@media (max-width: 1100px) and (min-width: 768px){
  .partForm, .partImageShokola {
    width: 50%;
  }
}

@media (min-width: 1200px){
  //GENERAL
  .desktop-none{
    display: none!important;
  }
  .w-md-2{
    width: 2%!important;
  }
  .w-md-5{
    width: 5%!important;
  }
  .w-md-10{
    width: 10%!important;
  }
  .w-md-15{
    width: 15%!important;
  }
  .w-md-20{
    width: 20%!important;
  }
  .w-md-25{
    width: 25%!important;
  }
  .w-md-30{
    width: 30%!important;
  }
  .w-md-33{
    width: 33%!important;
  }
  .w-md-35{
    width: 35%!important;
  }
  .w-md-40{
    width: 40%!important;
  }
  .w-md-60{
    width: 60%!important;
  }
  .w-md-65{
    width: 65%!important;
  }
  .w-md-70{
    width: 70%!important;
  }
  .w-md-80{
    width: 80%!important;
  }
  .w-md-90{
    width: 90%!important;
  }
  .w-md-95{
    width: 95%!important;
  }
  .w-md-98{
    width: 98%!important;
  }
}

@media (max-width: 1200px){
  //PAGE DE CONNEXION
  .partLogin {
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  .partForm .titreForm {
    margin-top: 0!important;
    margin-bottom: 0!important;
  }

  //GENERAL
  .modal-notifications {
    width: 100%;
    margin-left: 0%;
  }
  .respo-none {
    display: none!important;
  }
  .overflow-respo{
    overflow: scroll!important;
  }
  .contenu {
    width: 100% !important;
    margin-left: 0%;
  }
  .menu {
    display: none;
    width: 100%;
    margin-top: 97px;
    z-index: 50;
    overflow: scroll;
    padding-bottom: 82px;
  }
  .menu nav{
    padding: 0 1em;
  }
  .filtreParPersonne label {
    position: relative;
    right: 0px;
    top: -32px;
  }
  .liste .row{
    flex-wrap: nowrap!important;
    width: fit-content!important;
  }
  .liste .col-0-5 {
    width: 50px!important;
  }
  .liste .col-1 {
    width: 100px!important;
  }
  .liste .col-1-5 {
    width: 150px!important;
  }
  .liste .col-2 {
    width: 200px!important;
  }
  .liste .col-2-5 {
    width: 250px!important;
  }
  .liste .col-3 {
    width: 300px!important;
  }
  .liste .col-3-5 {
    width: 350px!important;
  }
  .liste .col-4 {
    width: 400px!important;
  }
  .liste .col-10 {
    width: 1000px!important;
  }
  #tache_edit_facturation label, #tache_edit_pole label {
    overflow: visible;
  }
  .staticFooterMenu {
    width: 100%;
    margin-right: 0!important;
    top: 87%;
    height: 13%;
  }

  //CLIENT
  .infoBar {
    margin-top: 4%;
  }
  .valBar .valCT {
    font-size: 30px;
  }
}

